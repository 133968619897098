<template>
  <v-container fluid>
    <!-- Summary section -->

    <h3 class="text-subtitle-1 mb-1">ที่ใกล้เคียง</h3>

    <v-dialog v-model="dialogloading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading.. please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="blue darken-2"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">check_circle</v-icon>
      <b>{{ snackbar.msg }}</b>
      <v-btn dark text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <v-row class="mb-2">
      <v-col lg="4" md="6" sm="12" cols="12">
        <OrderTotalHistoryCard
          title="TOTAL"
          avatar_bg="blue"
          avatar_icon="mdi-cart-outline"
          :item_id="oid"
          ref="ordertotalhistorycard"
        />
      </v-col>
    </v-row>

    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :loading="dataTableLoading"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
        mobile-breakpoint="200"
        disable-sort
        disable-filtering
      >
        <!-- table top section -->

        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td class="caption">
              <template v-if="item.tracking">
                <span>{{ item.tracking }}</span><br />
                <v-icon small outlined color="red">mdi-truck-fast</v-icon>
                <span v-if="item.tracking_date">{{ item.tracking_date | moment("DD-MM-YYYY") }}</span>
              </template>
            </td>
            <td>{{ item.branch }}</td>
            <td>
              <v-chip
                label
                :dark="true"
                outlined
                color="blue darken-3"
                small
                v-if="item.tag"
              >
                {{ item.tag }}
              </v-chip>
              {{ item.name }}
              <div class="grey--text text--darken-3 caption3 my-td-width3"><v-icon color="blue lighten-2" size="15">mdi-phone</v-icon> {{ item.tel1 }}, {{ item.tel2}}</div>

            </td>
            <td>
              <v-chip
                outlined
                color="green"
                :dark="true"
                v-if="item.is_cod == paymentMethods[1].id"
                class="ma-2"
                small
              >
                <b>COD{{ item.amount | currency(" ฿", 0) }}</b>
              </v-chip>
              <v-chip outlined light small v-else class="ma-2">
                PAID {{ item.amount | currency("฿", 0) }}
              </v-chip>
            </td>
            <!-- <td>
              <v-select
                style="width:150px;"
                v-model="item.is_cod"
                :items="paymentMethods"
                :rules="[v => !!v || 'กรุณาเลือก']"
                label="ประเภทชำระ"
                item-text="name"
                item-value="id"
                item-color="blue"
                @change="changePaymentMethod(item)"
              ></v-select>
            </td> -->

            <td>
              <OrderStatus :status="item.status"></OrderStatus>
            </td>

            <!-- <td>
              <v-select
                style="width:150px;"
                v-model="item.status"
                :items="statusSelect"
                :rules="[v => !!v || 'กรุณาเลือก']"
                label="สถานะ"
                item-text="name"
                item-value="id"
                @change="changeStatus(item)"
              ></v-select>
            </td> -->
            <td class="caption">
              <div class="my-td-width">
                <span v-if="item.createdAt">{{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}</span><br />
                <span v-if="item.lastedOrder" class="font-weight-bold">
                  <v-icon color="red" small>sync</v-icon> ID:
                  {{ item.lastedOrderId }}
                  <span class="font-weight-medium red--text"
                    >({{ item.lastedOrder | moment("from") }})</span
                  >
                </span>
              </div>
            </td>
            <td>
              <v-icon size="18" class="mr-1" @click="orderIdSheet = item.id">airplay</v-icon>
              <v-icon size="18" class="mr-2" @click="editItem(item)">search</v-icon>
              <v-icon size="18" class="mr-2" @click="deleteItem(item)">delete</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" max-width="290">
        <v-card>
          <v-card-title class="headline">Confirm Delete</v-card-title>
          <v-card-text class="body">
            ต้องการลบรายการนี้ใช่หรือไม่?
            <h3>{{ selectedItem }}</h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
            <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <OrderDetailSheet :id="orderIdSheet" @clear-id="orderIdSheet = ''"></OrderDetailSheet>
  </v-container>
</template>

<script>
import api, { ORDERSTATUS, PAYMENTMETHOD } from "@/services/api";
import _ from "lodash";
import OrderTotalHistoryCard from "@/components/cards/OrderTotalHistoryCard";
import OrderStatus from "@/components/helpers/OrderStatus";
import OrderDetailSheet from "@/components/OrderDetailSheet";

export default {
  name: "OrderHistory",
  props: ["orderid"],
  data() {
    return {
      orderIdSheet: '',
      oid: "",
      dialogloading: false,
      snackbar: {
        active: false,
        msg: ""
      },
      snackbarFaild: {
        active: false,
        msg: ""
      },
      statusSelect: [
        { id: ORDERSTATUS.WAIT, name: "รอ" },
        { id: ORDERSTATUS.COMPLETED, name: "สำเร็จ" },
        { id: ORDERSTATUS.PENDING, name: "รอจัดการ" },
        { id: ORDERSTATUS.CANCELLED, name: "ยกเลิก" },
        { id: ORDERSTATUS.PRINTED, name: "PRINTED" }
      ],
      paymentMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" }
      ],
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Tracking", value: "tracking" },
        { text: "Branch", value: "branch" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        { text: "ใหม่ / สั่งล่าสุด", value: "date" },
        { text: "Action", value: "action" }
      ],
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: "",
      showWarningMsgNewOrder: {
        alert: false,
        type: "info",
        msg: "มีออเดอร์ใหม่"
      },
      dataTableLoading: true,
      totalItems: 0,
      options: {} // for dataTable
    };
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    OrderTotalHistoryCard,
    OrderStatus,
    OrderDetailSheet
  },
  mounted() {
    console.log("this orderhistory components is running");
    // this.oid = this.orderid;
    // this.loadData();
    //console.log('mounted -->', this.orderid)
  },
  methods: {
    loadData() {
      this.getDataFromApi()
        .then(data => {
          this.mDataArray = data.rows;
          this.totalItems = data.totalItems;
          this.summaryOrder = { ...data.summary };
        })
        .catch(error => {
          console.log("myerror :" + error);
        });
    },
    getDataFromApi() {
      this.dialogloading = true;
      this.dataTableLoading = true;
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options;
        api.getOrderHistory(this.oid, page, itemsPerPage).then(result => {
          let rows = result.data.rows;
          let totalItems = result.data.count;

          //console.log(rows);

          setTimeout(() => {
            this.dataTableLoading = false;
            this.dialogloading = false;
            resolve({ rows, totalItems });
          }, 500);
        });
      });
    },
    editItem(item) {
      this.oid = item.id;
      this.loadData();
      this.$emit("load-new-order", item);
      //this.$router.push(`/billing-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    changeStatus: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, status } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("status", status);
      let result = await api.setOrderStatus(formData);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนสถานะสำเร็จ`
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนสถานะไม่สำเร็จ`
        };
      }
      this.dataTableLoading = false;
      this.loadData();
      this.$refs.ordertotalhistorycard.loadData();
    }, 1000),
    async confirmDelete() {
      let result = await api.deleteOrder(this.selectedItemId);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ"
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง"
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.loadData();
    },
    changePaymentMethod: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, is_cod } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("is_cod", is_cod);
      let result = await api.setOrderPaymentMethod(formData);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินสำเร็จ`
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินไม่สำเร็จ`
        };
      }
      this.dataTableLoading = false;
      this.loadData();
    }, 1000)
  },
  computed: {},
  watch: {
    showWarningMsgNewOrder() {
      setTimeout(() => {
        this.showWarningMsgNewOrder.alert = false;
      }, 5000);
    },
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        //console.log(this.options.page, this.options.itemsPerPage)
        this.oid = this.orderid;
        this.loadData();
      },
      deep: true
    },
    orderid: {
      //console.log(newVal, oldVal)
      handler(newVal, oldVal) {
        // console.log('watch.orderid -->', newVal, oldVal)
        this.oid = newVal;
        //this.loadData();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
   .my-td-width{
    width: 120px;
  }
  .my-td-width3 {
    width: 200px;
  }
</style>
