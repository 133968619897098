<template>
  <v-card id="stock_card">
    <v-list-item two-line>
      <v-list-item-avatar  :color="avatar_bg" size="40">
        <!-- ทำไมถึงใส่เครือ่งหมาย : ที่ color เพื่อให้ props สามัญรู้ว่าจะมีการ bind ผูก ค่าจากตัวเข้ามา ไม่งั้นจะมองเป็น string -->
        <v-icon color="white" size="18">{{ avatar_icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2 grey--text text--darken-2">{{
          title
        }}</v-list-item-title>
        <v-list-item-subtitle class="text-subtitle-2" v-if="total"
          >รวม {{ total | currency("", 0) }} |
          {{ amount | currency("฿", 0) }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "OrderTotalHistoryCard",
  props: ["title", "avatar_bg", "avatar_icon", "item_id"], //การกำหนด properties ให้กับ components
  data() {
    return {
      total: '',
      amount: '',
      ordersid: ''
    };
  },
  methods: {
    async getTotal() {
      if (this.ordersid) {
        let result = await api.getOrderTotalRelateById(this.ordersid);
        if (result.data.result == "ok") {
          this.total = result.data.message.total;
          this.amount = result.data.message.amount;
        }
      }
    },
    loadData() {
        this.getTotal();
    }
  },
  mounted() {
    // console.log("mounted:", this.items_id);
    //this.loadData();
  },
  watch: {
    item_id: function(newVal, oldVal) {
        this.ordersid = newVal;
        this.loadData();
    }
  }
};
</script>

<style></style>
